import React from "react"

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import Container from '../components/container'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import SearchBar from '../components/search-bar'

import MetaSchemaSEO from '../components/seo';


export const query = graphql`
  query {
    indexQuery: strapi{ siteSetting {
      id
      site_name
      default_seo_description
      featured_pages {
        id
        title
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
    }
    }
    newsPages: strapi{ pages(
      sort: "updated_at:desc"
      limit: 20
      publicationState: LIVE
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          published_at
          parent {
            id
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
    }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);
  const postNodes = data.newsPages.pages


  return (
    <Layout>
      <MetaSchemaSEO
      />
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
      <Container>
        <h1>Latest Pages</h1>
        {postNodes && (
          <>
            <BlogPostPreviewList
              nodes={postNodes}
            />
          </>
        )}
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
    </Layout>
  )
}

export default IndexPage
